.footer {
  background: #fff;
  padding: 40px 0 0;
  .container {
    max-width: 1440px;
  }
  .nav-bar-container {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 30px;
    margin-bottom: 30px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
    .cta-container {
      display: flex;
      align-items: center;
      gap: 20px;
      .icon-container {
        height: 64px;
        width: 64px;
        svg {
          height: 64px;
          width: auto;
        }
      }
      .content-container {
        font-size: 18px;
        font-weight: 400;
        line-height: 21.78px;
        color: #000;
        a {
          color: #ff4446;
          font-weight: 800;
          font-size: 30px;
          line-height: 26.31px;
          white-space: nowrap;
          margin-top: 8px;
          display: inline-block;
        }
      }
    }
    .columns-container {
      display: flex;
      justify-content: space-between;
      gap: 60px;
      text-align: center;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
      }
      a {
        font-size: 18px;
        font-weight: 500;
        line-height: 21.78px;
      }
    }
  }
  .blurb {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 40px;
  }
  .copyright-container {
    background: #000;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    line-height: 16.94px;
    padding: 30px 0;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 480px) {
        flex-direction: column;
        gap: 20px;
      }
      .actions-container {
        display: flex;
        align-items: center;
        gap: 40px;
        @media (max-width: 480px) {
          flex-direction: column;
          gap: 2px;
        }
        a {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 400;
          padding: 10px 0;
        }
      }
    }
  }
}
