.header {
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  z-index: 100;
  @media (max-width: 480px) {
    height: 88px;
    padding: 0 6px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    height: 102px;
    position: relative;
    .main-logo-container {
      a {
        cursor: default;
        svg {
          height: 46px;
          width: auto;
          @media (max-width: 480px) {
            height: 34px;
          }
        }
      }
    }
    .actions-container {
      display: flex;
      align-items: center;
      .cta-container {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 16.94px;
        text-align: right;
        padding-right: 60px;
        margin-right: 18px;
        height: 54px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 480px) {
          font-size: 10px;
          line-height: 12.1px;
          padding-right: 40px;
        }
        a {
          display: block;
          line-height: 24.2px;
          font-weight: 700;
          font-size: 20px;
          @media (max-width: 480px) {
            font-size: 14px;
            line-height: 12.1px;
          }
        }
        .image-container {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 50%;
          background: #d0d0d0;
          height: 52px;
          width: 52px;
          text-align: center;
          @media (max-width: 480px) {
            top: 10px;
            height: 32px;
            width: 32px;
          }
          img {
            position: relative;
            top: 3px;
            height: 47.05px;
            width: 47.05px;
            @media (max-width: 480px) {
              height: 32px;
              width: 32px;
            }
          }
          .dot {
            position: absolute;
            top: -1px;
            right: -1px;
            height: 14px;
            width: 14px;
            background: #05e100;
            border-radius: 50%;
            box-shadow: o 4px 4px 0 rgba(0, 0, 0, 0.15);
          }
        }
      }
      .flyout-trigger {
        cursor: pointer;
        position: relative;
        top: 0px;
        span {
          height: 3px;
          width: 22px;
          background: #000;
          display: block;
          margin: 0 0 4px;
          transform-origin: 5% 50%;
          transition: transform 0.25s ease;
          &:nth-child(even) {
            width: 16px;
            margin-left: 6px;
          }
        }
        &.isOpen {
          span {
            background: #ff4446;
            &:nth-child(1) {
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              opacity: 0;
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
  .flyout-container {
    position: absolute;
    height: 0;
    overflow: hidden;
    width: 100%;
    top: 96px;
    transition: right 0.25s ease;
    z-index: 100;
    background: #fff;
    box-shadow: 0 30px 30px 0px rgba(0, 0, 0, 0.15);
    @media (max-width: 480px) {
      height: auto;
      width: auto;
      right: -100vw;
      top: 88px;
    }
    &.isOpen {
      height: auto;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      @media (max-width: 480px) {
        right: 0;
        border-top: none;
      }
    }
    .flyout {
      padding: 20px;
    }
    .sign-in-container {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 14px 0;
      margin-top: 14px;
    }
  }
  &.dark {
    background: rgba(17, 24, 39, 1);
    box-shadow: none;

    --logo-primary: var(--red);
    --logo-secondary: #fff;
    &.yellow {
      --logo-primary: var(--yellow);
    }
    .container {
      .actions-container {
        .cta-container {
          .cta {
            > div {
              color: #fff;
            }
          }
          a {
            color: #fff;
          }
        }
        .flyout-trigger {
          span {
            background: #fff;
          }
        }
      }
    }
    .flyout-container {
      background: rgba(17, 24, 39, 1);
      .flyout {
        a {
          color: #fff !important;
        }
      }
    }
  }
}
