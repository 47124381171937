.page-not-found-container {
  .container {
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h1 {
    margin-bottom: 0;
  }
  p {
    margin: 0 0 60px;
  }
}
